import React from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.css'
import axios from 'axios'
import logoSrc from "../assets/img/decathlon.svg"
import { ReactComponent as PackageIcon } from "../assets/img/Order.svg"
import { ReactComponent as ReturnsIcon } from "../assets/img/Refound.svg"
import { ReactComponent as OfficeIcon } from "../assets/img/Office.svg"
import { ReactComponent as RentalIcon } from "../assets/img/Rental.svg"
import { toast } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"
import PinSubmitOrders from './PinSubmitOrders'
import PinSubmitOffice from './PinSubmitOffice'
import Returns from './Returns'
import PinRental from './PinRental'

class PageSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef()
    this.state = {
      activeTab: '1'
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  render() {

    return (
      <>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          ref={this.recaptchaRef}
          size="invisible"
        />
        <Row className="justify-content-center footer-row">
          <Nav tabs className="menu-nav">
            <NavItem className="menu-item">
              <NavLink
                className={classnames({ 'active-menu-item': this.state.activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}
              >
                <Row className="justify-content-center icon-row">
                  <OfficeIcon />
                </Row>
                <Row className="justify-content-center text-row">
                  <span className="nav-text">Office</span>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="menu-item">
              <NavLink
                className={classnames({ 'active-menu-item': this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
              >
                <Row className="justify-content-center icon-row">
                  <RentalIcon />
                </Row>
                <Row className="justify-content-center text-row">
                  <span className="nav-text">Rental</span>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="menu-item">
              <NavLink
                className={classnames({ 'active-menu-item': this.state.activeTab === '3' })}
                onClick={() => { this.toggle('3'); }}
              >
                <Row className="justify-content-center icon-row">
                  <PackageIcon />
                </Row>
                <Row className="justify-content-center text-row">
                  <span className="nav-text">Encomendas</span>
                </Row>
              </NavLink>
            </NavItem>
            <NavItem className="menu-item">
              <NavLink
                className={classnames({ 'active-menu-item': this.state.activeTab === '4' })}
                onClick={() => { this.toggle('4'); }}
              >
                <Row className="justify-content-center icon-row">
                  <ReturnsIcon />
                </Row>
                <Row className="justify-content-center text-row">
                  <span className="nav-text">Devoluções</span>
                </Row>
              </NavLink>
            </NavItem>
          </Nav>
        </Row>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <PinSubmitOffice recaptchaRef={this.recaptchaRef} />
          </TabPane>
          <TabPane tabId="2">
            <PinRental recaptchaRef={this.recaptchaRef} />
          </TabPane>
          <TabPane tabId="3">
            <PinSubmitOrders recaptchaRef={this.recaptchaRef} />
          </TabPane>
          <TabPane tabId="4">
          <Returns recaptchaRef={this.recaptchaRef} />
          </TabPane>
        </TabContent>
      </>
    )
  }
}
export default PageSwitcher
