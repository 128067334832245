import React from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.css'
import axios from 'axios'
import logoSrc from "../assets/img/lokk_logo.svg"
import { toast } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"
import { ReactComponent as RentalDraw } from "./../assets/img/Rental-draw.svg"

class PinRental extends React.Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = this.props.recaptchaRef
    this.state = {
      phoneNumber: '',
      pin: '',
      pinRegex: new RegExp('^[0-9]{6}$'),
      phoneNumberRegex: new RegExp('^[0-9]{9}$'),
      showOpenDoorModal: false,
      showConfirmationModal: false,
      doorNumber: null,
      buttonDisabled: true,
    }
  }

  handlePin = (e) => {
    this.setState({ pin: e.target.value }, () => {
      this.confirmButtonDisabled()
    })
  }

  handlePhoneNumber = (e) => {
    this.setState({ phoneNumber: e.target.value }, () => {
      this.confirmButtonDisabled()
    })
  }

  confirmButtonDisabled = () => {
    if (this.state.pin.length <= 0 ||
      this.state.phoneNumber.length <= 0
    ) {
      this.setState({ buttonDisabled: true })
    } else {
      this.setState({ buttonDisabled: false })
    }
  }

  openDoor = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/delivery/pickUpPackageWithQr?pin=${this.state.pin}&recipientPhone=${this.state.phoneNumber}`, {
      headers: {
        Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
      }
    })
      .then(res => {
        if (res.data.ResultCode == 1) {
          this.setState({ pin: '', recipientPhone: '' })
          this.setState({ showOpenDoorModal: false })
          this.setState({ showConfirmationModal: true })
        } else {
          toast.error("Ocorreu um erro. Por favor tente novamente.", { toastId: 8 })
        }
      })
      .catch((err) => {
        toast.error("Ocorreu um erro. Por favor tente novamente.", { toastId: 9 })
      })
  }

  onSubmit = () => {
    if (this.state.pin.match(this.state.pinRegex) && this.state.phoneNumber.match(this.state.phoneNumberRegex)) {

      this.recaptchaRef.current.reset()
      this.recaptchaRef.current.executeAsync().then(token => {
        let my_captcha_response = window.grecaptcha.getResponse()
        if (my_captcha_response) {
          axios.get(`${process.env.REACT_APP_API_URL}api/delivery/verifyPickUpPackageWithQr?pin=${this.state.pin}&recipientPhone=${this.state.phoneNumber}`, {
            headers: {
              Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
            }
          })
            .then(res => {
              if (res.data.ResultCode == 1) {
                this.setState({ doorNumber: res.data.Data.assignedLocker.lockerMetraId }, () => {
                  this.setState({ showOpenDoorModal: true })
                })
              } else {
                toast.error("Ocorreu um erro. Por favor confirme os dados inseridos.", { toastId: 10 })
              }
            })
            .catch((err) => {
              toast.error("Ocorreu um erro. Por favor confirme os dados inseridos.", { toastId: 11 })
            })
        } else {
          toast.warning("Por favor complete o CAPTCHA!", { toastId: 12 })
        }
      })
    } else {
      toast.error("Por favor certifique-se que os dados inseridos são válidos!", { toastId: 13 })
    }
  }

  toggleOpenDoorModal = () => {
    this.setState({ showOpenDoorModal: !this.state.showOpenDoorModal })
  }

  toggleConfirmationModal = () => {
    this.setState({ showConfirmationModal: !this.state.showConfirmationModal })
  }

  render() {
    const { showOpenDoorModal, showConfirmationModal } = this.state

    const openDoorModal = (
      <Modal isOpen={showOpenDoorModal} toggle={this.toggleOpenDoorModal} centered size="xl">
        <ModalHeader className="modal-bg" toggle={this.toggleOpenDoorModal}><span className="modal-title">Código confirmado!</span></ModalHeader>
        <ModalBody className="modal-body modal-size">
          <p>A sua encomenda encontra-se na seguinte porta:</p>
          <div className="door-number-container">
            <span className="door-number">{this.state.doorNumber}</span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => this.openDoor()}>Abrir porta</Button>
        </ModalFooter>
      </Modal>
    )

    const confirmationModal = (
      <Modal isOpen={showConfirmationModal} toggle={this.toggleConfirmationModal} centered size="xl">
        <ModalHeader className="modal-bg" toggle={this.toggleConfirmationModal}><span className="modal-title">Porta aberta!</span></ModalHeader>
        <ModalBody className="modal-body modal-size">
          <p>Obrigado pela sua preferência!</p>
        </ModalBody>
      </Modal>
    )

    return (
      <Row className="m-0 justify-content-center orders-row">
        {openDoorModal}
        {confirmationModal}
        <Col className="col-12 p-0 main-col justify-content-center">
          <Row className="m-0 main-row align-items-center">
            <Col className="p-0 col-12">
              <Form>
                <Col className="p-0">
                  <Row className="justify-content-center m-0">
                    <Col className="col-12 p-0">
                      <h4 className="text-center title">Recolher encomenda</h4>
                      <p className="text-center text-muted">Insira o seu número de telemóvel e o PIN recebido por SMS</p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center order-draw">
                    <RentalDraw />
                  </Row>
                  <Row>
                    <Col className="col-12 p-0">
                      <FormGroup>
                        <Input
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          placeholder="Nº de Telemóvel"
                          onChange={(e) => this.handlePhoneNumber(e)}
                          value={this.state.phoneNumber}
                          pattern="[0-9]*" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 p-0">
                      <FormGroup>
                        <Input
                          type="password"
                          name="pin"
                          id="pin"
                          placeholder="PIN"
                          onChange={(e) => this.handlePin(e)}
                          value={this.state.pin}
                          pattern="[0-9]*" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 p-0">
                      <FormGroup>
                        <Button className="btn" disabled={this.state.buttonDisabled ? true : false} block onClick={this.onSubmit}>Confirmar</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
export default PinRental