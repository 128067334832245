import React from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.css'
import axios from 'axios'
import { ReactComponent as LogoSrc } from "../assets/img/lokk_logo.svg"
import { toast } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"
import { ReactComponent as RefundDraw } from "./../assets/img/RefundDraw.svg"

class Returns extends React.Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = this.props.recaptchaRef
    this.state = {
      recipientPhone: '',
      recipientName: '',
      packageLocation: "",
      packageId: '',
      packageValue: '',
      description: '',
      recipientPhoneRegex: new RegExp('^[0-9]{9}$'),
      showOpenDoorModal: false,
      showConfirmationModal: false,
      showMainForm: true,
      doorNumber: null,
      size: 's',
      locations: [
        {
          id: 1,
          value: "1",
          location: "Famalicão",
          city: "Porto",
        },
        {
          id: 2,
          value: "2",
          location: "Carcavelos",
          city: "Cascais",
        },
        {
          id: 3,
          value: "3",
          location: "Peso da Régua",
          city: "Vila Real",
        },
      ],
      buttonDisabled: true,
    }
  }

  handleRecipientPhone = (e) => {
    this.setState({ recipientPhone: e.target.value }, () => {
      this.confirmButtonDisabled()
    })
  }

  handleRecipientName = (e) => {
    this.setState({ recipientName: e.target.value }, () => {
      this.confirmButtonDisabled()
    })

  }

  handlePackageId = (e) => {
    this.setState({ packageId: e.target.value }, () => {
      this.confirmButtonDisabled()
    })
  }

  handleDescription = (e) => {
    this.setState({ description: e.target.value }, () => {
      this.confirmButtonDisabled()
    })
  }

  handleLocation = (e) => {
    this.setState({
      packageValue: e.target.value,
      packageLocation: this.state.locations[e.target.value].location,
      packageLocationCity: this.state.locations[e.target.value].city
    }, () => {
      this.confirmButtonDisabled()
    })

  }

  confirmButtonDisabled = () => {
    if (this.state.recipientPhone.length <= 0 ||
      this.state.recipientName.length <= 0 ||
      this.state.packageLocation.length <= 0 ||
      this.state.packageId.length <= 0 ||
      this.state.description <= 0
    ) {
      this.setState({ buttonDisabled: true })
    } else {
      this.setState({ buttonDisabled: false })
    }
  }

  assignLocker = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/delivery/assignLocker?locationId=${process.env.REACT_APP_LOCATION_ID}&size=${this.state.size}&packageId=${this.state.packageId}`, {
      headers: {
        'Authorization': 'Bearer NThjOTdhZWU0NTA5YTU2ZTg3ZTQ4YmU4YzRiNGFlYjY4ZjllNjU4MDE4YTg0ZmY0M2UzZmZlZGVjYmVmNjczZg'
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.ResultCode == 1) {
          this.setState({
            assignedLocker: res.data.Data.assignedLocker.number,
            showOpenDoorModal: true,
            showMainForm: false,
            showConfirmationModal: false
          })
        } else {
          toast.error("Ocorreu um erro. Por favor tente novamente.", { toastId: 20 })
        }
      })
      .catch((err) => {
        toast.error("Ocorreu um erro. Por favor tente novamente.", { toastId: 21 })
      })
  }

  storePackage = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/delivery/storePackage?packageId=${this.state.packageId}`, {
      headers: {
        'Authorization': 'Bearer NThjOTdhZWU0NTA5YTU2ZTg3ZTQ4YmU4YzRiNGFlYjY4ZjllNjU4MDE4YTg0ZmY0M2UzZmZlZGVjYmVmNjczZg'
      }
    })
      .then(res => {
        console.log(res)
        if (res.data.ResultCode == 1) {
          this.setState({
            packageId: '',
            packageLocation: '',
            recipientName: '',
            recipientPhone: '',
            showConfirmationModal: false,
            showMainForm: true,
          })
          toast.success("A porta foi aberta. Por favor guarde a sua encomenda e feche a porta.", { toastId: 22 })
        } else {
          toast.error("Ocorreu um erro. Por favor tente novamente.", { toastId: 23 })
        }
      })
      .catch((err) => {
        toast.error("Ocorreu um erro. Por favor tente novamente.", { toastId: 24 })
      })
  }

  confirmInput = () => {
    this.setState({
      showOpenDoorModal: true,
      showMainForm: false,
    })
  }

  onSubmit = () => {
    if (this.state.recipientPhone.match(this.state.recipientPhoneRegex)) {
      this.recaptchaRef.current.reset()
      this.recaptchaRef.current.executeAsync().then(token => {
        let my_captcha_response = window.grecaptcha.getResponse()
        if (my_captcha_response) {
          axios.post(`${process.env.REACT_APP_API_URL}api/delivery/returnOrder`, {
            'packageId': this.state.packageId,
            'recipientName': this.state.recipientName,
            'recipientPhone': this.state.recipientPhone,
            'recipientNumber': '',
            'recipientEmail': ''
          }, {
            headers: {
              'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
            }
          })
            .then(res => {
              console.log(res)
              if (res.data.ResultCode == 1) {
                this.assignLocker()
              } else {
                toast.error("Não foi possível criar a devolução!", { toastId: 25 })
              }
            })
            .catch((err) => {
              toast.error("Não foi possível criar a devolução!", { toastId: 26 })
            })
        } else {
          toast.warning("Por favor complete o CAPTCHA!", { toastId: 27 })
        }
      })
    } else {
      toast.error("Por favor certifique-se que os dados inseridos são válidos!", { toastId: 28 })
    }
  }

  toggleOpenDoorModal = () => {
    this.setState({ showOpenDoorModal: !this.state.showOpenDoorModal })
  }

  toggleConfirmationModal = () => {
    this.setState({ showConfirmationModal: !this.state.showConfirmationModal })
  }

  render() {
    const { showOpenDoorModal, showConfirmationModal } = this.state

    const openDoorModal = (
      <Col className="col-12 p-0 confirm-data-col justify-content-center">
        <Row className="m-0 justify-content-center main-row">
          <Col className="p-0 col-12">
            <Row className="justify-content-center title-row">
              <p>Devolver Encomenda</p>
            </Row>
            <Row className="justify-content-center subtitle-row">
              <p>Confirme os dados da devolução</p>
            </Row>
            <Row className="justify-content-center refund-draw">
              <RefundDraw />
            </Row>
            <div className="container-fluid data-container">
              <Row className="first-row">
                <Col className="first-col">
                  <span className="left-span">Nº de talao</span>
                </Col>
                <Col className="hr-col">
                  <hr />
                </Col>
                <Col className="last-col">
                  <span className="right-span">{this.state.packageId}</span>
                </Col>
              </Row>
              <Row className="second-row">
                <Col className="first-col">
                  <span className="left-span">Nome</span>
                </Col>
                <Col className="hr-col">
                  <hr />
                </Col>
                <Col className="last-col">
                  <span className="right-span">{this.state.recipientName}</span>
                </Col>
              </Row>
              <Row className="third-row">
                <Col className="first-col">
                  <span className="left-span">Telemóvel</span>
                </Col>
                <Col className="hr-col">
                  <hr />
                </Col>
                <Col className="last-col">
                  <span className="right-span">{this.state.recipientPhone}</span>
                </Col>
              </Row>
              <Row className="last-row">
                <Col className="first-col">
                  <span className="left-span">Localização</span>
                </Col>
                <Col className="hr-col">
                  <hr />
                </Col>
                <Col className="last-col">
                  <span className="right-span">{this.state.packageLocation}<span> {this.state.packageLocationCity}</span></span>
                </Col>
              </Row>
            </div>
            {/* <Row className="justify-content-center">
          <FormGroup>
            <Input
              placeholder="Nome"
              type="text"
              name="recipientName"
              id="recipientName"
              onChange={(e) => this.handleRecipientName(e)}
              value={this.state.recipientName} />
          </FormGroup>
        </Row>
        <Row className="justify-content-center">
          <FormGroup>
            <Input
              placeholder="N Telemóvel"
              type="number"
              name="recipientPhone"
              id="recipientPhone"
              onChange={(e) => this.handleRecipientPhone(e)}
              value={this.state.recipientPhone}
              pattern="[0-9]*" />
          </FormGroup>
        </Row>
        <Row className="justify-content-center">
          <FormGroup>
            <Input
              placeholder="Nº do Talão"
              type="number"
              name="packageId"
              id="packageId"
              onChange={(e) => this.handlePackageId(e)}
              value={this.state.packageId} />
          </FormGroup>
        </Row> */}
            <Row className="justify-content-center">
              <Col lg="12" sm="12">
                <Button className="change-data-button" block onClick={() => this.setState({ showOpenDoorModal: false, showMainForm: true })}>Alterar dados</Button>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Col lg="12" sm="12">
                <Button block onClick={() => this.onSubmit()}>Pedir um cacifo</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )

    const confirmationModal = (
      <Col sm="12" lg="12">
        <Row className="justify-content-center">
          <p>O seguinte cacifo foi-lhe atribuído</p>
        </Row>
        <div className="door-number-container">
          <Row className="justify-content-center">
            <span className="door-number">{this.state.assignedLocker}</span>
          </Row>
        </div>
        <Row className="justify-content-center mt-3">
          <Col sm="12" lg="12">
            <Button block onClick={() => this.storePackage()}>Abrir porta</Button>
          </Col>
        </Row>
      </Col>
    )

    const mainForm = (
      <Col className="col-12 p-0 order-col justify-content-center">
        <Row className="m-0 justify-content-center main-row">
          <Col className="p-0 col-12">
            <Form>
              <Col>
                <Row className="justify-content-center">
                  <Col sm="12" lg="12">
                    <h4 className="text-center title">Devolver Encomenda</h4>
                    <p className="text-center text-muted">Insira os dados da encomenda</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="p-0 col-12">
                    <FormGroup>
                      <Input
                        placeholder="Nome"
                        type="text"
                        name="recipientName"
                        id="recipientName"
                        onChange={(e) => this.handleRecipientName(e)}
                        value={this.state.recipientName} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="p-0 col-12">
                    <FormGroup>
                      <Input
                        placeholder="Nº Telemóvel"
                        type="number"
                        name="recipientPhone"
                        id="recipientPhone"
                        onChange={(e) => this.handleRecipientPhone(e)}
                        value={this.state.recipientPhone}
                        pattern="[0-9]*" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="p-0 col-12">
                    <FormGroup>
                      <Input
                        placeholder="Nº Talão"
                        type="number"
                        name="packageId"
                        id="packageId"
                        onChange={(e) => this.handlePackageId(e)}
                        value={this.state.packageId} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="select-shop-column col-auto">
                    <FormGroup>
                      <Label for="store">Nome da Loja</Label>
                      <Input type="select" name="store" id="store" value={this.state.packageValue} onChange={(e) => this.handleLocation(e)}>
                        <option value="" disabled selected>Localização</option>
                        {
                          this.state.locations.map((location, index) =>
                            <option id={location.id} value={index}>{location.location}</option>
                          )
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="p-0 col-12">
                    <FormGroup>
                      <Input
                        placeholder="Motivo da devolução"
                        type="textarea"
                        name="description"
                        id="description"
                        onChange={(e) => this.handleDescription(e)}
                        value={this.state.description} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="p-0 col-12">
                    <FormGroup>
                      <Button className="btn" block disabled={this.state.buttonDisabled ? true : false} onClick={this.confirmInput}>Confirmar</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Col>
        </Row>
      </Col>
    )

    return (
      <Row className="m-0 justify-content-center returns-row">
        {this.state.showOpenDoorModal && openDoorModal}
        {this.state.showConfirmationModal && confirmationModal}
        {this.state.showMainForm && mainForm}
      </Row>
    )
  }
}
export default Returns
