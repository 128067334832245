import React from 'react';
import PageSwitcher from './components/PageSwitcher'
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import './App.css';

function App() {
  return (
    <div>
      <ToastContainer 
      limit={1}/>
      <PageSwitcher />
    </div>
  );
}

export default App;
